@font-face {
  font-family: "Sans";
  src: url("../font/open-sans.regular.ttf");
}

@font-face {
  font-family: "Sans";
  src: url("../font/open-sans.regular.ttf");
  font-weight: 700;
}

@font-face {
  font-family: 'Sans';
  src: url("../font/open-sans.semibold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Oswald";
  src: url("../font/Oswald-Regular.ttf");
}

html,
body {
  font-family: "Sans";
}

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #393f41 !important;
}

.adventure-green-dot {
  height: 7px;
  width: 7px;
  background-color: #567B5C;
  border-radius: 50%;
  display: inline-block;
  margin-top: 7px;
}

.grey-dot {
  height: 7px;
  width: 7px;
  background-color: #B6BABB;
  border-radius: 50%;
  display: inline-block;
  margin-top: 7px;
}

.p-card {
  border-radius: 0px !important;
}
.qstn {
  text-align: right;
  font: normal normal bold 20px/24px Open Sans;
  letter-spacing: 1.4px;
  color: #ffaf13 !important;
  text-transform: uppercase;
  font-weight: bold !important;
}

.fquote-left-column-width {
  width: 100% !important;
}

.viewpolicy-panel-width {
  width: 1382px !important;
}
.text-color-energy-yellow {
  color: #ffaf13 !important;
}

.call {
  text-align: right;
  font: normal normal normal 18px/24px Open Sans;
  letter-spacing: 1.26px;
  color: #ffffff !important;
  text-transform: uppercase;
}
.font-card-tl {
  font-family: Oswald !important;
}

.esign-panel .p-panel .p-panel-header {
  border: none !important;
  padding: 1rem;
  background: #f8f9fa;
  color: #495057;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.MuiCard-root {
  padding: 0.75rem !important;
}

.MuiFormControl-root {
  margin: 0px !important;
}

.navbar-popover {
  padding: 0.75rem !important;
  margin-top: 0.5rem !important;
  width: 10rem !important;
}

.navbar-button-bg {
  background-color: #888c8d !important;
  border: 1px solid white;
}

/* .MuiPopover-paper {
  padding:0.75rem !important;
} */
.MuiDialogTitle-root {
  font-family: "Oswald" !important;
  font-size: 20px !important;
  color: #007db8 !important;
  font-weight: 600 !important;
}
.esign-panel .p-panel .p-panel-content {
  padding: 0.5rem;
  border: none !important;
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 0 none;
}

.bg-9C9FA0 {
  background-color: #9C9FA0 !important;
}

.heading .p-card-title {
  text-align: left;
  font: normal normal medium 24px/36px Oswald;
  font-family: "oswald" !important;
  font-size: 18px !important;
  letter-spacing: 0.67px !important;
  color: #393f41;
  text-transform: capitalize !important;
  opacity: 1;
}

.qq-width .p-card-content {
  padding: 0rem 0 !important;
}

.qq-width .p-card-body {
  padding: 0rem 0 !important;
}

.homeAlignment {
  margin-left: 0px;
}

ul.proof-of-purchase {
  list-style-type: disc !important;
}

/* .partitioned {
  padding-left: 15px !important;
  letter-spacing: 40px !important;
  border: 0 !important;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%) !important;
  background-position: bottom !important;
  background-size: 48px 1px !important;
  background-repeat: repeat-x !important;
  background-position-x: 33px !important;
  width: 224px !important;
  outline : none !important;
} */
.oswald {
  font-family: Oswald !important;
}

/*1 .partitioned {
  padding-left: 7px !important;
  letter-spacing: 13px !important;
  border: 0 !important;
  background-image: linear-gradient(to left, #393F41 70%, rgba(255, 255, 255, 0) 0%) !important;
  background-position: bottom !important;
  background-size: 25px 1px !important;
  background-repeat: repeat-x !important;
  background-position-x: 19px !important;
  width: 124px !important;
  outline: none !important;
} */

.moreinfo-table table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #B6BABB;
  font-family: 'Sans';
}

.moreinfo-table th, td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #B6BABB;
}

.moreinfo-table tr:nth-child(odd) {
  background-color: #E0E0E0;
}

.moreinfo-table tr:first-child {
  background-color: #ffffff !important;
}


.inputStyle {
  width: 1rem !important;
  height: 3rem;
  margin: 0rem;
  font-size: 2rem;
  border-radius: 0px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #ffffff !important;
}

.claimnumber .p-column-title {
  color: #393F41 !important
}

.button-orange-bg {
  background-color: #c75814 !important;
}

.heading .p-card-content {
  text-align: center;
}

.quote-list {
  display: inline-block;
  text-align: left;
}

.panel-list {
  display: inline-block;
  text-align: left;
}

.right-column-width {
  width: 100% !important;
}

.left-column-width {
  width: 100% !important;
}

/* .p-error {
  color: #616567 !important;
} */

.p-button:enabled:hover {
  border: 2px solid #c75814 !important;
}

.p-button {
  border: 2px solid #c75814 !important;
}

.bg-f5f5f5 {
  background-color: #f5f5f5 !important;
}

.quote-panel .p-panel-content {
  padding: 0rem !important;
  padding-left: 1.15rem !important;
}

/* .caret-down {
  content: url(https://fontawesome.com/icons/caret-down?s=solid&f=classic);
} */

.quote-card-radio-sm .p-card-content {
  padding: 0em !important;
}

.font-color-6B6C6D {
  color: #6b6c6d !important;
}

.tick-mark ul li::before {
  content: "\2022";
  color: #567b5c;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 20px;
}

.dashed-list ul {
  list-style-type: "-";
  text-indent: 5px;
}

::-webkit-input-placeholder {
  color: #c0c0c0 !important;
}


.MuiButtonBase-root.MuiRadio-root {
  padding-left: 0px !important;
}
.mobile-coverage-card .p-card-content {
  padding: 0rem 0rem !important;
}

.quote-top .p-card-body {
  border-radius: 8px 8px 0px 0px;
}

.quote-bottom .p-card-body {
  border-radius: 0px 0px 8px 8px;
}
.quote-card-radio .p-card-body:hover {
  background-color: #0000001a;
}

.quote-last .p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 8px;
}

.quote-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: skyblue !important;
  border: 2px solid skyblue !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6 !important;
  stroke-linecap: round;
}

.mobile-progress-bar .p-card-body {
  padding: 0rem 1rem !important;
}

.radio-selected {
  background: var(--adventure-green) 0% 0% no-repeat padding-box !important;
  background: #567b5c 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  opacity: 1 !important;
}
.quote-radiobutton .p-radiobutton-box.p-highlight {
  border-color: white !important;
  background: #567b5c !important;
}

.i-quote .p-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f5f5f5 !important;
  border: none !important;
}

.i-quote .p-panel-content {
  border: none !important;
}

.quote-radiobutton .p-radiobutton-box.p-highlight.p-focus {
  border-color: white !important;
  background: #567b5c !important;
  box-shadow: #495057 !important;
}

.MuiFilledInput-input {
  padding-top: 10px !important;
}

.summary-popover .MuiPopover-paper {
  background-color: #f5f5f6 !important;
}

.bg-f5f5f6 {
  background-color: #f5f5f6 !important;
}

.summary-card .p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.summary-width {
  width: 100%;
}
.letter-spacing-3 {
  letter-spacing: 3px !important;
}

.letter-spacing-1 {
  letter-spacing: 1px !important;
}

.letter-spacing-2 {
  letter-spacing: 2px !important;
}

.initial-quote-plan-bg {
  background-color: #9c9fa0 !important;
}

.initial-quote-plan-selected-bg {
  background-color: #567b5c !important;
}

.quote-card-radio .p-card-body {
  background: #9c9fa0 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  color: #ffffff !important;
  font: normal normal 800 18px/21px Open Sans !important;
  letter-spacing: 0.72px !important;
  text-transform: uppercase !important;
}

.headline-blue {
  color: #007db8 !important;
}

.quote-card-radio-select .p-card-body {
  background: #567b5c 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  color: #ffffff !important;
  font: normal normal 800 18px/21px Open Sans !important;
  letter-spacing: 0.72px !important;
  text-transform: uppercase !important;
  padding: 0.5rem !important;
}

.quote-card-radio-select {
  background: #567b5c 0% 0% no-repeat padding-box !important;
  color: white !important;
}

.quote-card-radio {
  background-color: #9c9fa0 !important;
  color: white !important;
}

.quote-card-radio .p-card-body {
  background: #9c9fa0 0% 0% no-repeat padding-box !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  color: #ffffff !important;
  font: normal normal 800 18px/21px Open Sans !important;
  letter-spacing: 0.72px !important;
  text-transform: uppercase !important;
  padding: 0.5rem !important;
}

.p-placeholder {
  color: #757575;
}

.field-checkbox,
.field-radiobutton {
  margin-bottom: 0rem !important;
}

.field-radiobutton:enabled:focus {
  border-color: #2196f3;
}

.field-radiobutton .p-highlight {
  border-color: #2196f3;
  background: #2196f3;
}

.p-splitter {
  border: none !important;
  background: #ffffff;
  border-radius: 3px;
  color: #495057;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  border-color: none !important;
}

.quote-panel .p-panel-header {
  border-bottom: none !important;
}

.mu-zip > .p-inputtext-sm {
  width: 200px;
  /* font: normal normal 1rem "Muli"; */
  /* letter-spacing: var(--unnamed-character-spacing-0); */
  text-align: left;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  /* border-bottom: 2px solid #ced4da !important;  */
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  /* letter-spacing: 0px; */
  opacity: 1;
  height: 41px;
  padding-left: 7px !important;

  letter-spacing: 13px !important;
  border: 0 !important;
  background-image: linear-gradient(
    to left,
    #393f41 70%,
    rgba(255, 255, 255, 0) 0%
  ) !important;
  background-position: bottom !important;
  background-size: 25px 1px !important;
  background-repeat: repeat-x !important;
  background-position-x: 19px !important;
  width: 126px !important;
}

.mu-zip .p-inputtext {
  border-bottom: 0 !important;
}

.border-asphalt-gray-60 {
  border: 1px solid #888c8d !important;
}

/* .iquote-sticky-right-panel {
  float: right;
  position: sticky;
  left: 54rem;
  margin-top: 5.5rem;
  z-index: 1;
  overflow-x: hidden;
  height: 100%;
  top: 4rem;
  bottom: 0;
} */

.age .p-inputtext {
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 1px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 1px !important;
}

/* .p-inputtext {
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.50) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 1px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 1px !important;
} */

.inputtext {
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 1px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 1px !important;
}

.MuiCard-root {
  border-radius: 0px !important;
}

.bg-white-input .p-inputtext {
  background-color: white !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 1px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 1px !important;
}

.initial-quote-card .p-dropdown {
  border: none !important;
}

.p-dropdown .p-dropdown-trigger {
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 1px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 1px !important;
}

.p-dropdown {
  background: #ffffff;
  border: none !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 3px;
}

.ziperror-background-image {
  background: url("../public/images/global/a5.png") !important;
}

.login-background-image {
  background: url("../public/images/global/login-bg.png") !important;
}

.tooltip-padding-top {
  padding-top: 0.85rem !important;
}

.dropdown .p-inputtext {
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 1px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.bg-white-input .p-inputtext {
  background-color: white !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 1px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 1px !important;
}

.dropdown .p-dropdown-trigger {
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 1px !important;
}

.viewpolicy-panel .p-panel-header {
  border: none !important;
  padding: 1rem;
  background: #888c8d !important;
  color: #ffffff !important;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  font-family: Oswald !important;
  font-size: 1.525rem !important;
}
.viewpolicy-panel-container .p-panel-header {
  border: none !important;
  padding: 1rem;
  background: #888c8d !important;
  color: #ffffff !important;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  font-family: Oswald !important;
  font-size: 1.525rem !important;
 
}
.viewpolicy-panel-container .p-panel-content {
  padding: 1rem 0rem !important;
  border: none !important;
  background: #f5f5f6 !important;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 0 none;
}
.viewpolicy-panel-container .p-panel-header .p-panel-title {
  display: flex !important;
  flex: 1 !important;
}

.viewpolicy-panel .p-panel-content {
  padding: 1rem 0rem !important;
  border: none !important;
  background: #f5f5f6 !important;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 0 none;
}

.bg-white-input .p-dropdown-trigger {
  background-color: white !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 1px !important;
}

input#coverage_start {
  width: 240px !important;
}

.dropdown-icon {
  content: "\f0d7";
}

div#year {
  border: 0 !important;
}
div#make {
  border: 0 !important;
}
div#model {
  border: 0 !important;
}
.asphalt-gray {
  background: #393f41 !important;
}

.font-color-asphalt-gray {
  color: #393f41 !important;
}

.font-color-asphalt-gray-60 {
  color: #888c8d !important;
}

.font-color-asphalt-gray-80 {
  color: #616567 !important;
}
/* .age .p-dropdown {
  background-color: rgba(0, 0, 0, 0.06) !important;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.50) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 5px !important;
} */
.mu-zip > .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;

  /* border-color: #6366F1; */
}

.qq-input-page .p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.start-quote-icon .pi {
  font-size: 1.5rem !important;
}

.adventure-green {
  color: #567b5c;
}

.initial-quote-card-width {
  width: 32rem !important;
}

.start-app-card .p-card .p-card-body {
  padding-top: 1rem;
  padding-bottom: 0rem !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.aboutyou-card .p-card .p-card-body {
  padding-top: 1rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.aboutyou-card .p-card .p-card-content {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.startapp-content .p-card {
  padding-top: 0px !important;
  padding-left: 1rem !important;
  background: #ffffff;
  color: #495057;
  box-shadow: none !important;
  border-radius: 3px;
}
.startapp-content .p-card .p-card-body {
  padding-top: 0px !important;
}
.startapp-content .p-card .p-card-content {
  padding-top: 0px !important;
  padding-left: 1rem !important;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px solid #dee2e6;
}

.pi-chevron-down:before {
  content: "\e902";
}

.pi-chevron-up:before {
  content: "\e903";
}

.hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.pi-angle-right:before {
  content: "\e932";
}

.pi-circle-fill:before {
  content: "\e9dd";
}

.pi-phone:before {
  content: "\e9b8";
}

.circle-icon {
  width: 11px;
  padding-top: 0.25rem !important;
  height: 13px;
}

.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid !important;
}
.p-radiobutton .p-radiobutton-box:hover {
  border-color: #495057;
}

.p-divider.p-divider-vertical {
  margin: 1rem 1rem !important;
  padding: 1rem 0 !important;
}

.p-divider.p-divider-vertical:before {
  border-left: 1px solid #d7d9d9 !important;
  padding: 1rem 0rem !important;
}

.bg-color-asphalt-gray-60 {
  background-color: #888c8d !important;
}

.p-radiobutton .p-radiobutton-box {
  border: 1px solid #888c8d !important;
  background: #ebecec !important;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  padding: 2px;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px !important;
  height: 12px !important;
  transition-duration: 0.2s;
  background-color: skyblue !important;
}

.shadow {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}

.qq-width {
  width: 100% !important;
}

.sticky-button {
  top: 4rem !important;
}

.quick-quote .p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 0px;
}

.age-suffix-sm {
  margin-top: -0.5rem;
  padding-top: 0rem;
  position: absolute;
  top: 26%;
  right: 0.75rem;
}

.startapp-card-mobile .p-card {
  background: #9e9e9e;
  color: #ffffff;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 72px !important;
  border-bottom-right-radius: 0px !important;
}

.startapp-card-mobile .p-card .p-card-content {
  padding: 0rem 0;
}

.startapp-card-mobile .p-card .p-card-body {
  padding-top: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.CircularProgressbar {
  width: 5rem !important;
  vertical-align: middle !important;
}

.mobile-progress-bar .p-card .p-card-body {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #567b5c !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #9e9e9e !important;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.final-quote-container {
  background-color: #d0d0d0 !important;
  border-color: #d0d0d0 !important;
}

/* Mobile Stepper CSS--- */

.wrapper {
  width: 237px !important;
  font-family: "Muli" !important;
  font-size: 14px !important;
}
.StepProgress {
  position: relative;
  padding-left: 36px;
  list-style: none;
  /* height: 327px; */
}
.StepProgress::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 6px;
  left: 23px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #757575;
}
.StepProgress-item {
  position: relative;
}
.StepProgress-item:not(:last-child) {
  padding-bottom: 35px;
}
.StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -21px;
  height: 100%;
  width: 10px;
  margin-left: 8px;
}
.StepProgress-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 2px;
  left: -20px;
  width: 18px;
  height: 18px;
  border: 2px solid #757575;
  border-radius: 50%;
  background-color: #bdbdbd;
  margin-left: 0rem;
}
.StepProgress-item.is-done::before {
  border-left: 2px solid #293889;
  top: 4px;
}
.StepProgress-item.is-done::after {
  font-size: 10px;
  color: #fff;
  text-align: center;
  border: 2px solid #293889;
  background-color: #293889;
}
.StepProgress-item.current::before {
  border-left: 2px solid #959595;
}
.StepProgress-item.current::after {
  padding-top: 1px;
  width: 19px;
  height: 18px;
  top: 1px;
  left: -21px;
  font-size: 14px;
  text-align: center;
  color: green;
  border: 2px solid #293889;
  background-color: white;
}
.StepProgress strong {
  display: block;
}

.pi-plus:before {
  content: "\e90d";
}

.vehicle-summary-padding {
  padding-left: 2rem;
  padding-right: 2rem;
}

.field-radiobutton-focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #dbdbdb !important;
  border-color: #2196f3 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #888c8d !important;
  background: white !important;
}

.safe-rider-padding {
  padding: 0 1rem 1rem 1rem ;  
}

/* .safe-rider-question-padding {
  padding-left:1.5rem;
} */

.safe-rider-bonus {
  line-height: 2 !important;
}

.joint-top {
  border-top: 2px dashed rgb(41, 56, 137);
  width: 23px;
  margin-top: 0.45rem;
  padding-left: 0rem;
}

.joint-right {
  border-right: 2px dashed rgb(41, 56, 137);
  height: 16px;
  margin-right: 0.5rem;
}

.initial .p-divider.p-divider-horizontal .p-divider-content {
  padding: 0rem;
}

.initial .p-divider.p-divider-horizontal:before {
  border-top: 1px #ffffff;
}

.p-splitter .p-splitter-gutter {
  width: 0px !important;
}

.initial-right-card-bg {
  background-color: #9e9e9e !important;
}

.initial-quote-card .p-card .p-card-body {
  padding: 0rem 0rem !important;
}

.initial-quote-card .p-card .p-card-content {
  padding: 0rem 1rem !important;
}

.right-card-mobile {
  background-color: #aeaeae;
}

.retrieve-quote .p-dialog-header {
  display: none !important;
}

.select-modal .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 1rem !important;
  padding-left: 0.5rem !important;
}

.select-modal .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #495057;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem !important;
}

.select-modal .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 1.5rem 1.5rem !important;
}

.selected-modal-content {
  width: 9rem !important;
}

.select-modal .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0rem !important;
  text-align: right;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0 1rem 1rem 1rem;
  display: none !important;
}

/* .initial-quote-card .p-dropdown-trigger-icon {
  border: 1px solid lightgrey !important;
  padding: 0.3rem !important;
  border-radius: 15px !important;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%) !important;
} */

.text-padding {
  padding-top: 0.6rem !important;
}

/* .stack-margin .css-19s5k2p-MuiStack-root>:not(style)+:not(style) {
  margin: 0;
  margin-top: 0px;
} */

.final-quote-divider .p-divider.p-divider-horizontal:before {
  border-top: 1px solid #a9a9a9 !important;
}

.stack-divider .p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 0.5rem 1rem !important;
  background: #e5e4e2 !important;
  color: #495057;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.dashboard-panel-header .p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 0.5rem 1rem !important;
  background: white !important;
  color: #495057;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.dashboard-card-1 .p-card-content {
  padding: 0rem 0 !important;
}
.dashboard-card-1 .p-card-body {
  padding-bottom: 0.25rem !important;
  padding-top: 0.7rem !important;
}

.dashboard-card-2 .p-card-body {
  padding: 0rem !important;
}

.dashboard-card-2 .p-card-content {
  padding: 0.5rem 0 !important;
}

.dashboard-background {
  background: #888c8d !important;
}

.qq-bg {
  background-color: white;
}

.startapp-left-panel-width {
  width: 100vw !important;
}

.qq-plan-options-width {
  width: 100% !important;
}

.MuiInputBase-root {
  border-radius: 0px !important;
  color:#495057 !important;
}

.MuiSelect-iconFilled {
  font-size: 32px !important;
  color: #b0b2b3 !important;
}

.p-inputtext:enabled:focus {
  outline-offset: 0;
box-shadow: none !important
}

.cancel-dialog {
  width: auto !important;
}

.viewpolicy-panel-header-width {
  width:auto !important;
}

.viewpolicy-panel-header-display {
  display: flex !important;
  flex-direction: row !important;
  column-gap: 1rem !important;
}


@media only screen and (min-width: 768px) {
  /* .stack-margin .css-19s5k2p-MuiStack-root>:not(style)+:not(style) {
    margin: 0;
    margin-top: 0px;
} */
.viewpolicy-panel-header-width {
  width:99rem !important;
}
.cancel-dialog {
  width: 50rem !important;
}

.right-panel-height {
  height: 18.25rem !important;
}

.MuiDialogTitle-root {
  font-family: Oswald !important;
  font-size: 30px !important;
  color: #007db8 !important;
  font-weight: 600 !important;
}
  .payment-card-width {
    width: 650px;
  }
  .viewprofile-card-width {
    width: 1300px;
  }

  .initial-back-button {
    margin-top: 15rem !important;
    margin-bottom: 6rem !important;
  }

  .purchase-card-padding {
    padding-left: 16.438rem;
  }

  .left-padding {
    padding-left: 11rem !important;
  }

  .stepper-padding {
    padding-left:10rem !important;
  }

  .page-margin-top {
    margin-top: 4rem !important;
  }

  /* .MuiRadio-root.Mui-checked {
  color:#54BCDD !important;
} */

  .color-54BCDD {
    color: #54bcdd !important;
  }

  .aboutyou-padding-left {
    padding-left: 10rem !important;
  }

  .qq-plan-options-width {
    width: 37.3rem !important;
  }

  .startapp-left-panel-width {
    width: 40rem !important;
  }

  .dashboard-card-2 .p-card-body {
    padding: 1rem !important;
  }

  .aboutyou-card .p-card .p-card-body {
    padding-top: 1rem !important;
    padding-bottom: 0rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .quote-card-radio-select .p-card-body {
    background: #567b5c 0% 0% no-repeat padding-box !important;
    border-radius: 3px !important;
    opacity: 1 !important;
    color: #ffffff !important;
    font: normal normal 800 18px/21px Open Sans !important;
    letter-spacing: 0.72px !important;
    text-transform: uppercase !important;
    padding: 0.5rem !important;
  }

  .qq-width .p-card-content {
    padding: 1rem 0 !important;
  }

  .qq-bg {
    background-color: #eeeeee;
  }

  .qq-width .p-card-body {
    padding: 1rem !important;
  }

  .quick-quote-padding {
    margin-left: 14rem !important;
    padding-left: 0.25rem !important;
  }

  .dashboard-card-2 .p-card-content {
    padding: 1rem 0 !important;
  }

  .p-sidebar {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: none !important;
  }

  .dashboard-card-1 .p-card-content {
    padding: 0rem 0 !important;
  }
  .dashboard-card-1 .p-card-body {
    padding-bottom: 0.25rem !important;
    padding-top: 0.7rem !important;
  }

  .dashboard-vehicle-scrollbar::-webkit-scrollbar {
    width: 0.75em !important;
  }

  .dashboard-vehicle-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .dashboard-vehicle-scrollbar::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .p-scrollpanel .p-scrollpanel-bar {
    background: lightgray !important;
    border: 0 none;
  }
  .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 16px;
    /* width: 30rem !important; */
    max-height: calc(100% - 32px);
    outline: 0;
  }

  .retrieve-quote .p-dialog-header {
    display: none !important;
  }

  .selected-modal-content {
    width: 10rem !important;
  }

  .final-quote-padding {
    padding-left: 21rem !important;
    padding-right: 36rem !important;
  }

  .initial-right-card-bg {
    background-color: #bdbdbd !important;
  }

  .initial-customised-vehicle-bg {
    background-color: #bdbdbd;
  }

  .initial-quote-card .p-card .p-card-body {
    padding: 1rem !important;
  }

  .initial-quote-card .p-card .p-card-content {
    padding: 0rem 1rem !important;
  }

  .top-padding {
    padding-left: 15rem !important;
  }

  .top-padding .p-card .p-card-body {
    padding-bottom: 0rem !important;
  }

  .top-padding .p-card .p-card-content {
    padding-bottom: 0rem !important;
  }

  .final-quote-padding .p-card .p-card-body {
    padding-bottom: 0rem !important;
  }

  .final-quote-padding .p-card .p-card-content {
    padding-bottom: 0rem !important;
  }

  .triangle-right {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-right: 24px solid #eeeeee;
    border-bottom: 25px solid transparent;
  }

  .age-suffix {
    margin-top: -0.5rem;
    padding-top: 0rem;
    position: absolute;
    top: 35%;
    right: 0.75rem;
  }

  .quick-quote .p-card {
    background: #ffffff;
    color: #495057;
    box-shadow: 0 2px 0px -1px rgb(0 0 0 / 20%), 0 1px 0px 0 rgb(0 0 0 / 14%),
      0 1px 0px 0 rgb(0 0 0 / 12%);
    border-radius: 0px;
  }

  .qq-width {
    width: 19rem !important;
  }

  .startapp-dropdown .p-dropdown {
    background: #ffffff;
    border: none !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 3px;
    height: 2.375rem;
  }
  .startapp-content .p-card {
    background: #ffffff;
    color: #495057;
    box-shadow: none !important;
    border-radius: 3px;
  }

  .startapp-content .p-card .p-card-body {
    padding: 0rem 1rem;
  }

  .startapp-content .p-card .p-card-content {
    padding-bottom: 1rem !important;
    padding-top: 0rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .MuiStep-root {
    width: 8rem !important;
  }

  .finalApp .MuiStep-root {
    width: 9.5rem !important;
  }

  .startapp-divider .p-divider.p-divider-horizontal:before {
    border-top: 1px solid #dee2e6 !important;
  }

  .initial-quote-back-margin {
    margin-left: 13.25rem !important;
  }

  .intial-quote-top-content-padding {
    padding-left: 201px !important;
  }

  .startapp-card .p-card {
    background: #ffffff;
    color: #495057;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
      0 1px 3px 0 rgb(0 0 0 / 12%);
    border-bottom: 2px solid #e0e0e0 !important;
    border-left: 2px solid #e0e0e0 !important;
    border-right: 0px !important;
    border-top: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 48px !important;
    border-bottom-right-radius: 0px !important;
  }

  .startapp-padding {
    padding-left: 18.75rem;
  }

  .start-app-page .p-card {
    background: #ffffff;
    color: #495057;
    box-shadow: none !important;
    border-radius: 6px;
    padding-bottom: 0px !important;
  }

  .start-app-card .p-card .p-card-body {
    padding: 1rem;
  }

  .vehicle-details-2 .p-card .p-card-body {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .vehicle-details-2-card1 .p-card-content {
    padding: 0rem !important;
  }

  .vehicle-box-shadow .p-card {
    box-shadow: none !important;
  }

  .vehicle-details-2 .p-card .p-card-content {
    padding-bottom: 0rem !important;
  }

  .vehicle-details-2 .p-card {
    box-shadow: none !important;
  }

  .vehicle-summary .p-card .p-card-content {
    padding-bottom: 0rem !important;
  }

  .vd-padding {
    padding-left: 26rem;
  }

  .add-vehicle-padding {
    padding-left: 15.5rem !important;
    padding-right: 16rem !important;
  }

  .vehicle-summary-padding {
    padding-left: 16rem !important;
    padding-right: 15rem !important;
  }

  .operator-detail-padding {
    padding-left: 17rem !important;
    padding-right: 15rem !important;
  }

  .operator-assignment-padding {
    padding-left: 17rem !important;
  }

  .vehicle-detail-padding {
    padding-left: 16rem !important;
    padding-right: 15rem !important;
  }
  /* .calendar .p-datepicker {
  top: 374px !important;
  left: 363px !important;
} */

  .p-datepicker table td {
    padding: 0.2rem !important;
  }

  .p-connected-overlay-enter-done {
    transform: none;
    width: 265px !important;
    /* top: 386.17188px !important;
  left: 578.969px !important; */
  }

  .p-datepicker table td > span {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border-radius: 50% !important;
    transition: box-shadow 0.2s !important;
    border: 1px solid transparent !important;
  }

  .p-datepicker .p-datepicker-buttonbar {
    padding: 0rem 0 !important;
    border-top: 1px solid #dee2e6 !important;
  }

  .iquote-sticky-right-panel {
    float: right;
    position: sticky;
    left: 54rem;
    margin-top: 6.5rem;
    z-index: 1;
    overflow-x: hidden;
    height: 100%;
    top: 4rem;
    bottom: 0;
  }

  .operator-details-sections {
    padding-left: 26.2rem;
    padding-top: 0.5rem !important;
  }

  .otherQuestions-padding {
    padding-left: 16.8rem;
    padding-right: 33rem;
  }

  .safe-rider-bg {
    background-color: white;
    background-image: linear-gradient(to right, #84878b, 1%, white);
  }

  .purchase-card-bg {
    /* background-color: white; */
    background-image: linear-gradient(180deg, #b6babb, #c5cdd1);
  }

  .fquote-left-column-width {
    width: 650px !important;
  }

  .safe-rider-note {
    line-height: 2 !important;
    padding-left: 19rem !important;
    padding-right: 10rem !important;
  }

  .safe-rider-bonus {
    line-height: 2 !important;
    width: 34rem;
  }

  .safe-rider-padding {
    padding-left: 13rem;
  }

  .safe-rider-question-padding {
    padding-left: 17rem;
  }

  .dashed-list ul {
    list-style-type: "-";
    text-indent: 15px;
  }

  .safe-rider-button-bar {
    position: sticky !important;
    bottom: 0px !important;
  }

  /* .stepper-web .p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px !important;
  height: 20px !important;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.stepper-web .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: darkgray !important;
  background: darkgrey !important;
}

.stepper-web .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
} */

  .stepper-web .p-radiobutton {
    width: 40px;
    height: 20px;
  }

  .MuiStepLabel-root:hover {
    transform: scale(1.2);
  }

  .homeAlignment {
    margin-left: 226px;
  }

  .MuiSelect-select {
    padding-top: 8px !important;
  }



  .MuiInputBase-root {
    border-radius: 0px !important;
  }

  .MuiSelect-iconFilled {
    font-size: 32px !important;
    color: #b0b2b3 !important;
  }

  .final-calendar .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.3375rem 0.4375rem;
  }

  .mail > .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 1.5rem 2rem 1.5rem 2rem !important;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .mail .p-dialog .p-dialog-header .p-dialog-title {
    color: #007db8 !important;
  }

  .mail .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 2rem 1.5rem 2rem !important;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    color: #007db8 !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    font-family: Oswald !important;
  }
  .mail .p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 1.5rem 1.5rem !important;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .left-column-width {
    width: 600px !important;
  }

  .right-column-width {
    width: 335px !important;
  }

  .login-text-wrapper-position {
    position: relative;
    top: 5rem;
    right: 14rem;
  }

  .summary-width {
    width: 52rem;
  }

  .qq-rightpanel-margin {
    margin-top: 100px !important;
  }

  .initialQuote-flex .p-card .p-card-content {
    padding: 0rem 0 !important;
  }

  .initialQuote-flex .p-card {
    box-shadow: none !important;
  }

  .footer-divider .MuiDivider-root {
    border: 1px solid #ffffff !important;
    opacity: 0.1 !important
  }

  .plan {
    font-size: 1.5rem;
    color: white;
  }

  .plan b {
    font-size: 2rem;
    font-weight: 500 !important;
    color: white;
  }

  .initial-right-card .p-card .p-card-body {
    padding: 1rem 1rem 1rem 1rem !important;
  }

  .initial-right-card .p-card .p-card-content {
    padding: 0rem !important;
  }

  .p-splitter .p-splitter-gutter {
    width: 0px !important;
  }

  .p-splitter {
    border: none !important;
    background: #ffffff;
    border-radius: 3px;
    color: #495057;
  }

  .login-padding-bottom {
    padding-bottom: 15rem !important;
  }
}

.app .p-card-body {
  width: 100% !important;
}

.credit-score {
  background: #393f41;
}

.credit-score-text {
  text-align: center;
  margin: 0;
}

.cov-end-date {
  background: #f5f5f5;
  width: 55.875rem;
  height: auto;
  position: relative;
  right: 6rem;
}

.child-cov-end-date {
  position: inherit;
  left: 6.5rem;
}

.font_color_energy_yellow {
  color: #ffaf13 !important;
}

.child-cov-oversees-duty  {
  position: inherit;
  left: 6.5rem;
}
